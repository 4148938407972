import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import { ReactComponent as PdfLogo } from '../../../../assets/images/help/pdf.svg';
import { ReactComponent as JpgLogo } from '../../../../assets/images/help/jpg.svg';
import { ReactComponent as PngLogo } from '../../../../assets/images/help/png.svg';
import { ReactComponent as YoutubeLogo } from '../../../../assets/images/help/youtube.svg';
import { ReactComponent as SvgLogo } from '../../../../assets/images/help/svg.svg';
import videosLogo from '../../../../assets/images/help/videos.jpg';
import webinarsLogo from '../../../../assets/images/help/webinars.jpg';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var getResourcesCardData = function getResourcesCardData(isAdmin) {
  return [{
    id: 1,
    title: 'Ima Tools',
    url: '/information-center/resources/ima-tools'
  }, {
    id: 2,
    title: 'Branding Materials',
    url: '/information-center/resources/branding-materials'
  }, isAdmin && {
    id: 3,
    title: 'Product and business presentations',
    url: '/information-center/resources/product-and-business-presentations'
  }, {
    id: 4,
    title: 'Video',
    image: videosLogo,
    url: '/information-center/resources/video'
  }, isAdmin && {
    id: 5,
    title: 'Webinars',
    image: webinarsLogo,
    url: '/information-center/resources/webinars'
  }].filter(Boolean);
};
export var imaToolsDataAdminOnly = [{
  id: 2,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneAcademy Educational and Legal Packages User Manual',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneAcademy Educational And Legal Packages User Manual view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneAcademy Educational And Legal Packages User Manual download.pdf'
}, {
  id: 3,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneAcademy/ OneForex User Manual',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneAcademy: OneForex User Manual view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneAcademy: OneForex User Manual download.pdf'
}, {
  id: 4,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Cryptocurrency and MLM regulations',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Cryptocurrency And MLM Regulations view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Cryptocurrency And MLM Regulations download.pdf'
}, {
  id: 5,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'OneEcosystem Bonus Generating Opportunities',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Bonus Generating Opportunities view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Bonus Generating Opportunities download.jpg'
}, {
  id: 6,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'How to qualify for Matching Bonus?',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Matching Bonus view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Matching Bonus download.jpg'
}, {
  id: 7,
  image: ___EmotionJSX(PngLogo, null),
  title: 'How to qualify for Direct Sales Bonus?',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Direct Sales Bonus view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Direct Sales Bonus download.png'
}, {
  id: 8,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'How to qualify for Network Bonus?',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Network Bonus view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Network Bonus download.jpg'
}, {
  id: 9,
  image: ___EmotionJSX(PngLogo, null),
  title: 'How to qualify for Start Up Bonus?',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How to qualify for startup_bonus_view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How to qualify for startup_bonus_download.png'
}, {
  id: 10,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'Leadership Plan : Sapphire',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Sapphire view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Sapphire download.jpg'
}, {
  id: 11,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'Leadership Plan : Ruby',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_ruby_view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_ruby_download.jpg'
}, {
  id: 12,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Leadership Plan : Emerald',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Emerald view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Emerald download.png'
}, {
  id: 13,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'Leadership Plan : Diamond',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_diamond_view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_diamond_download.jpg'
}, {
  id: 14,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'Leadership Plan : Black Diamond',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Black Diamond view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Black Diamond download.jpg'
}, {
  id: 15,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'Leadership Plan : Blue Diamond',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_blue_diamond_view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_blue_diamond_download.jpg'
}, {
  id: 16,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'Leadership Plan : Crown Diamond',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Crown Diamond view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Crown Diamond download.jpg'
}, {
  id: 17,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'DealShaker: How Does It Work? - infographics Chinese',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/dealshaker_how_it_works__chinese_infographics_Chinese_view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/dealshaker_how_it_works__chinese_infographics_Chinese_download.jpg'
}, {
  id: 18,
  image: ___EmotionJSX(JpgLogo, null),
  title: 'DealShaker: How Does It Work? - infographics ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/dealshaker_how_does_it_work_infographics_ENG_view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/dealshaker_how_does_it_work_infographics_ENG_download.jpg'
}, {
  id: 19,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Business Card for IMA - Editable/WHITE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card For IMA - Editable:WHITE - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card For IMA - Editable:WHITE - download.pdf'
}, {
  id: 20,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Business Card for IMA - Editable/BLUE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card For IMA - Editable:BLUE - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card For IMA - Editable:BLUE - download.pdf'
}, {
  id: 21,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Editable Letterhead For IMAs',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Editable Letterhead For IMAs - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Editable Letterhead For IMAs - download.pdf'
}, {
  id: 22,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Branded Envelope IMA - For Print',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Branded Envelope IMA - For Print - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Branded Envelope IMA - For Print - download.pdf'
}];
export var imaToolsData = [{
  id: 23,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneEcosystem - Bonus Generating Opportunities',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneEcosystem Bonus Generating Opportunities.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneEcosystem Bonus Generating Opportunities.pdf'
}, {
  id: 24,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneEcosystem - Leadership Program Ranks',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneEcosystem - Leadership Program Ranks.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneEcosystem - Leadership Program Ranks.pdf'
}];
export var brandingMaterialsData = [{
  id: 1,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'BRANDBOOK ONE ECOSYSTEM NETWORK',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Brandbook_ONE_ECOSYSTEM_NETWORK view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Brandbook_ONE_ECOSYSTEM_NETWORK download.pdf'
}, {
  id: 2,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'COMPILACIÓN BANNERS ROLL-UP ESPAÑOL',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Copilacio%CC%81n_compressed view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Copilacio%CC%81n_compressed download.pdf'
}, {
  id: 3,
  image: ___EmotionJSX(SvgLogo, null),
  title: 'IMA LOGO VERTICAL DARK BACKGROUND',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_dark background view.svg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_dark background download.svg'
}, {
  id: 4,
  image: ___EmotionJSX(SvgLogo, null),
  title: 'IMA LOGO HORIZONTAL DARK BACKGROUND',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_HORIZONTAL_dark background view.svg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_HORIZONTAL_dark background download.svg'
}, {
  id: 5,
  image: ___EmotionJSX(SvgLogo, null),
  title: 'IMA LOGO HORIZONTAL  LIGHT BACKGROUND',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_HORIZONTAL_white background view.svg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_HORIZONTAL_white background download.svg'
}, {
  id: 6,
  image: ___EmotionJSX(SvgLogo, null),
  title: 'IMA LOGO VERTICAL LIGHT BACKGROUND',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_white background view.svg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_white background download.svg'
}, {
  id: 7,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Business card back purple/blue',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 1 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 1 download.png'
}, {
  id: 8,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Business card back blue/green',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 2 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 2 download.png'
}, {
  id: 9,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Business card back orange/yellow',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 3 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 3 download.png'
}, {
  id: 10,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Business card back fucsia/orange',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 4 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 4 download.png'
}, {
  id: 11,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Business card back Light',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Back1 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Back1 download.png'
}, {
  id: 12,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Business card back Dark ',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Back2 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Back2 download.png'
}, {
  id: 13,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Business card front Dark',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Face_Dark view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Face_Dark download.png'
}, {
  id: 14,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Business card front Light',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Face_Light view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Face_Light download.png'
}, {
  id: 15,
  image: ___EmotionJSX(PngLogo, null),
  title: 'EXAMPLE Business card front Dark',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ЕXAMPLE___Business Card Face_Dark view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ЕXAMPLE___Business Card Face_Dark download.png'
}, {
  id: 16,
  image: ___EmotionJSX(PngLogo, null),
  title: 'EXAMPLE Business card front Light',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/EXAMPLE___Business Card Face_Light view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/EXAMPLE___Business Card Face_Light download.png'
}, {
  id: 17,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 GALA NIGHT 1',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Gala Banner 1 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Gala Banner 1 download.pdf'
}, {
  id: 18,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 GALA NIGHT 2',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Gala Banner 2 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Gala Banner 2 download.pdf'
}, {
  id: 19,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 TRAINING 1',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Training Banner 1 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Training Banner 1 download.pdf'
}, {
  id: 20,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 TRAINING 2',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Training Banner 2 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Training Banner 2 download.pdf'
}, {
  id: 21,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 EDUCATION & LEARNING PICTURE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Education&Learning_PicOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Education&Learning_PicOnly download.pdf'
}, {
  id: 22,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 EDUCATION & LEARNING TEXT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Education&Learning_TextOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Education&Learning_TextOnly download.pdf'
}, {
  id: 23,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 HONESTY & FAIRNESS PICTURE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Honesty & Fairness_PicOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Honesty & Fairness_PicOnly download.pdf'
}, {
  id: 24,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 HONESTY & FAIRNESS TEXT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Honesty&Fairness_TextOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Honesty&Fairness_TextOnly download.pdf'
}, {
  id: 25,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 PASSION & INTEGRITY PICTURE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Passion&Integrity_PicOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Passion&Integrity_PicOnly download.pdf'
}, {
  id: 26,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 PASSION & INTEGRITY TEXT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Passion&Integrity_TextOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Passion&Integrity_TextOnly download.pdf'
}, {
  id: 27,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 STABILITY AND TRUST PICTURE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Stability&Trust_PicOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Stability&Trust_PicOnly download.pdf'
}, {
  id: 28,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 STABILITY AND TRUST TEXT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Stability&Trust_TextOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Stability&Trust_TextOnly download.pdf'
}, {
  id: 29,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 SYNERGY & TEAMWORK PICTURE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Synergy&Teamwork_PicOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Synergy&Teamwork_PicOnly download.pdf'
}, {
  id: 30,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Roll-up Banner 80X200 SYNERGY & TEAMWORK TEXT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Synergy&Teamwork_TextOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Synergy&Teamwork_TextOnly download.pdf'
}, {
  id: 31,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Branded Envelope BACK',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Envelope_BACK_MASTER view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Envelope_BACK_MASTER download.png'
}, {
  id: 32,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Branded Envelope FRONT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Envelope_FRONT_MASTER view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Envelope_FRONT_MASTER download.png'
}, {
  id: 33,
  image: ___EmotionJSX(PngLogo, null),
  title: 'Branded Letterhead A4',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Letterhead_A4_MASTER.psd view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Letterhead_A4_MASTER.psd download.png'
}];
export var productAndBusinessPresentationsData = [{
  id: 0,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Take Charge Of The Financial Revolution',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/white-paper-2022-view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/white-paper-2022-download.pdf'
}, {
  id: 1,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneEcosystem Business Presentation 2019',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Business Presentation 2019 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Business Presentation 2019 - downwoad.pdf'
}, {
  id: 2,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Compliance Regulations In The One Ecosystem Re-VISION',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Compliance Regulations In The One Ecosystem Re-VISION - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Compliance Regulations In The One Ecosystem Re-VISION - download.pdf'
}, {
  id: 3,
  image: ___EmotionJSX(PdfLogo, null),
  title: "One Ecosystem And DealShaker's Role In It",
  viewUrl: "https://onelife.blob.core.windows.net/help-faq/One Ecosystem And DealShaker's Role In It - view.pdf",
  downloadUrl: "https://onelife.blob.core.windows.net/help-faq/One Ecosystem And DealShaker's Role In It - download.pdf"
}, {
  id: 4,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneForex Business Presentation Part 2',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneForex Business Presentation Part 2 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneForex Business Presentation Part 2 - download.pdf'
}, {
  id: 5,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneForex Business Presentation Part 1',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneForex Business Presentation Part 1 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneForex Business Presentation Part 1 - download.pdf'
}, {
  id: 6,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneEcosystem Business Presentation_ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Business Presentation_ENG - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Business Presentation_ENG - download.pdf'
}, {
  id: 7,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Compliance_Workshop_May 2017',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Compliance_Workshop_May 2017 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Compliance_Workshop_May 2017 - download.pdf'
}, {
  id: 8,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Conducting Events - Rules And Compliance Policy',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Conducting Events - Rules And Compliance Policy - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Conducting Events - Rules And Compliance Policy - download.pdf'
}, {
  id: 9,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'How To Present The OneEcosystem Opportunity - Introduction To Compliance',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Present The OneLife Opportunity - Introduction To Compliance - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Present The OneLife Opportunity - Introduction To Compliance - download.pdf'
}, {
  id: 10,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'DealShaker Merchants Introduction_Chinese',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Merchants Introduction_Chinese - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Merchants Introduction_Chinese - download.pdf'
}, {
  id: 11,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'DealShaker Bonus Plan Presentation_ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Bonus Plan Presentation_ENG - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Bonus Plan Presentation_ENG - download.pdf'
}, {
  id: 12,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'DealShaker Bonus Plan Presentation_Chinese',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Bonus Plan Presentation_Chinese - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Bonus Plan Presentation_Chinese - download.pdf'
}, {
  id: 13,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'DealShaker: Detailed Merchant Guide_ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Detailed Merchant Guide_ENG - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Detailed Merchant Guide_ENG - download.pdf'
}, {
  id: 14,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'DealShaker Merchants Introduction_ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Merchants Introduction_ENG - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Merchants Introduction_ENG - download.pdf'
}, {
  id: 15,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Tips For Merchants_Chinese',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Tips For Merchants_Chinese - view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Tips For Merchants_Chinese - download.png'
}, {
  id: 16,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'DealShaker_Merchant Guide Presentation_Chinese',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker_Merchant Guide Presentation_Chinese - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker_Merchant Guide Presentation_Chinese - download.pdf'
}, {
  id: 17,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'MAB Product Presentation, Jan 2017_RUS',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Jan 2017_RUS - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Jan 2017_RUS - download.pdf'
}, {
  id: 18,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'MAB Product Presentation, Dec 2016_CN',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Dec 2016_CN - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Dec 2016_CN - download.pdf'
}, {
  id: 19,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'MAB Product Presentation, Dec 2016_ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Dec 2016_ENG - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Dec 2016_ENG - download.pdf'
}, {
  id: 20,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneCoin_The_Owner',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin_The_Owner - view .pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin_The_Owner - download.pdf'
}, {
  id: 21,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'How_To_Start_Your_Business',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How_To_Start_Your_Business - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How_To_Start_Your_Business - download.pdf'
}, {
  id: 22,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Status_of_the_Network_London_11_June_2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Status_of_the_Network_London_11_June_2016 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Status_of_the_Network_London_11_June_2016 - download.pdf'
}, {
  id: 23,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneCoinCloud_January2016_update',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoinCloud_January2016_update - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoinCloud_January2016_update - download.pdf'
}, {
  id: 24,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'New_Blockchain_OneCoin_London_11_June_2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/New_Blockchain_OneCoin_London_11_June_2016 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/New_Blockchain_OneCoin_London_11_June_2016 - download.pdf'
}, {
  id: 25,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'CoinRush_OneLife_Network_London_11_June_2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/CoinRush_OneLife_Network_London_11_June_2016 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/CoinRush_OneLife_Network_London_11_June_2016 - download.pdf'
}];
export var videoDataAdminOnly = [{
  id: 1,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'Welcome To The Future Of Cryptocurrency - OneCoin',
  viewUrl: 'https://www.youtube.com/watch?v=sWdScH8UTAs'
}, {
  id: 2,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin Vs. BitCoin',
  viewUrl: 'https://www.youtube.com/watch?v=VpsR0i3vx6A'
}, {
  id: 3,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'Mining Extravaganza 2015 OneCoin Hong Kong Event',
  viewUrl: 'https://www.youtube.com/watch?v=1FMeTbCXi3c'
}, {
  id: 4,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'One World Foundation',
  viewUrl: 'https://www.youtube.com/watch?v=J6X0vSZH3s0'
}, {
  id: 5,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin Presentation Sofia 04.04.2015',
  viewUrl: 'https://www.youtube.com/watch?v=_X0ct2KaSRA'
}, {
  id: 6,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin And Conligus: When Two Giants Join Forces',
  viewUrl: 'https://www.youtube.com/watch?v=WACWld80aAY'
}, {
  id: 7,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'Welcome Of The Year Of Gold - Aurum Gold Coin - In Gold We Trust',
  viewUrl: 'https://www.youtube.com/watch?v=1wWauTaXIPs'
}, {
  id: 8,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin Event: Dubai, May 15th, 2015 At The Madinat Jumeirah Resort!',
  viewUrl: 'https://www.youtube.com/watch?v=3iZOGOuopTE'
}, {
  id: 9,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin Dr.Ruja Ignatova Interview With Auditor Deyan Dimitrov',
  viewUrl: 'https://www.youtube.com/watch?v=WAIKyBF4GuE'
}, {
  id: 10,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'Audit Presentation, Dubai Event, May 15th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=UOSStXW9YbY'
}, {
  id: 11,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'Coin Vegas',
  viewUrl: 'https://www.youtube.com/watch?v=fp5JIOQbaeo'
}, {
  id: 12,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'One Vegas Teaser',
  viewUrl: 'https://www.youtube.com/watch?v=4L-_JUgE41o'
}, {
  id: 13,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'Amazing OneCoin Event In DUBAI',
  viewUrl: 'https://www.youtube.com/watch?v=avmGZoXYyl0'
}, {
  id: 14,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'Dr. Ruja Ignatova, Birthday Party 2015',
  viewUrl: 'https://www.youtube.com/watch?v=VOtkdUSk1UE&feature=youtu.be'
}, {
  id: 15,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'The OneCoin Leadership Program',
  viewUrl: 'https://www.youtube.com/watch?v=jnxyDRJ80Jo&feature=youtu.be'
}];
export var videoData = [{
  id: 59,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S2 E5 (04.2024)',
  viewUrl: 'https://youtu.be/TOd6Bmz64ns'
}, {
  id: 58,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'THE FUTURE STARTS TODAY 2024, MALAYSIA, DAY 2',
  viewUrl: 'https://youtu.be/IdtZh1N_nNg'
}, {
  id: 57,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'THE FUTURE STARTS TODAY - DAY 3 - DS TRAINING AND ONE VITA WORKSHOP',
  viewUrl: 'https://youtu.be/8gTGWBXL5DY'
}, {
  id: 56,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'THE FUTURE STARTS TODAY 2024, DAY 1 - OPENING CEREMONY',
  viewUrl: 'https://youtu.be/NO33ET05S2o'
}, {
  id: 55,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'THE FUTURE STARTS TODAY 2024, MALAYSIA',
  viewUrl: 'https://youtu.be/yLx7KCqjVOY'
}, {
  id: 54,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S2 E4 (03.2024)',
  viewUrl: 'https://youtu.be/zHIsZvfQ0mc'
}, {
  id: 53,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM SPECIAL EDITION - DEALSHAKER - (02.2024)',
  viewUrl: 'https://youtu.be/2LXrt7cVGlc'
}, {
  id: 52,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S2 E3 (02.2024)',
  viewUrl: 'https://youtu.be/Vt05zYzxLIk'
}, {
  id: 51,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'THE FUTURE STARTS TODAY WORLD CONGRESS PROMO VIDEO',
  viewUrl: 'https://youtu.be/YzSx64HGwvQ'
}, {
  id: 50,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'BLOCKCHAIN VENTSISLAV ZLATKOV SPEECH (2023)',
  viewUrl: 'https://youtu.be/5Tg496gS7Gc'
}, {
  id: 49,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S2 E2 (01.2024)',
  viewUrl: 'https://youtu.be/NV-2dg36KQw'
}, {
  id: 48,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'ONE ECOSYSTEM CEO CHRISTMAS GREETING 2023 ENG',
  viewUrl: 'https://youtu.be/yMQ4UceWcTE'
}, {
  id: 47,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OE HEADQUARTERS GRAND OPENING (2023)',
  viewUrl: 'https://youtu.be/YOIsRKj1eAE'
}, {
  id: 46,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM - Exclusive Birthday Episode (11.2023)',
  viewUrl: 'https://youtu.be/KH0-gV_K-sk'
}, {
  id: 45,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'ONE ECOSYSTEM ASIA TOUR VIDEO 2 (2023)',
  viewUrl: 'https://youtu.be/JCz10ynglak'
}, {
  id: 44,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'ONE ECOSYSTEM ASIA TOUR (2023)',
  viewUrl: 'https://youtu.be/gM7KXSZ_zVo'
}, {
  id: 43,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S1 E8',
  viewUrl: 'https://youtu.be/OzcKiT6xdn0'
}, {
  id: 42,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OE SYSTEM OF NETWORKING Promo',
  viewUrl: 'https://youtu.be/CZKgiFvYyyw'
}, {
  id: 41,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S1 E7 (10.2023)',
  viewUrl: 'https://youtu.be/NxaZKjdMIV8'
}, {
  id: 40,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'DS CONVERSION POOL - CEO ANNOUNCEMENT (2023)',
  viewUrl: 'https://youtu.be/w-Gh03YYYow'
}, {
  id: 39,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S1 E6 (09.2023)',
  viewUrl: 'https://youtu.be/AvJlQ9QEdJ8'
}, {
  id: 38,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S1 E5 (08.2023)',
  viewUrl: 'https://youtu.be/9NVGzxFYsn4'
}, {
  id: 37,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S1 E4 (07.2023)',
  viewUrl: 'https://youtu.be/WFFy-erTnEg'
}, {
  id: 36,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'HOW TO TOP UP YOUR DIGITAL CASH WALLET',
  viewUrl: 'https://youtu.be/FQugTECYQ4g'
}, {
  id: 35,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'BLOCKCHAIN DEPLOYMENT PROCESS',
  viewUrl: 'https://youtu.be/V0qoWXbDAM8'
}, {
  id: 34,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S1 E3 (03.2023)',
  viewUrl: 'https://youtu.be/4MooaTEs0Xk'
}, {
  id: 33,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEXT LEVEL GLOBAL EVENT DAY 2 (2023)',
  viewUrl: 'https://youtu.be/o9Gi0LK_Rb0'
}, {
  id: 32,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEXT LEVEL GLOBAL EVENT DAY 1 (2023)',
  viewUrl: 'https://youtu.be/yNF3M0dy_Xc'
}, {
  id: 31,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'ONE ECOSYSTEM REFORM HELSINKI',
  viewUrl: 'https://youtu.be/qHnQuA3Dn_Q'
}, {
  id: 30,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'ONE VOYAGE',
  viewUrl: 'https://youtu.be/j0tg0FowWp4'
}, {
  id: 29,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'ONE VITA',
  viewUrl: 'https://youtu.be/FDcyHuyE4KU'
}, {
  id: 28,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'NEWSROOM S1 E2 (01.2023)',
  viewUrl: 'https://youtu.be/ocut-0dBsTc'
}, {
  id: 27,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'ONE ECOSYSTEM NEWSROOM S1 E1 (11.2022)',
  viewUrl: 'https://youtu.be/kkFT2ZvyCJg'
}, {
  id: 26,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'DIAMOND TRIP 2023 - VIETNAM',
  viewUrl: 'https://youtu.be/sR7A4h7ESbE'
}, {
  id: 25,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'REFORM - FINLAND EVENT (2022)',
  viewUrl: 'https://youtu.be/j8160Rx4X78'
}, {
  id: 24,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'THE ERA OF THE ONE (2022)',
  viewUrl: 'https://youtu.be/rzwIODCMtxo'
}, {
  id: 23,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'CRYPTO CENTER GRAND OPENING 2022',
  viewUrl: 'https://youtu.be/WsQT4nF_0RU'
}, {
  id: 22,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'STEP UP DIGITAL EVENT 2022',
  viewUrl: 'https://youtu.be/kYqU0uKbRuQ'
}, {
  id: 21,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'ONE ECOSYSTEM TEAM BUILDING GREECE 2022',
  viewUrl: 'https://youtu.be/cblp1ltd0B4'
}, {
  id: 20,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'ONE ECOSYSTEM TEAM BUILDING SUMMER 2022',
  viewUrl: 'https://youtu.be/s7Tpt8Tv7LQ'
}, {
  id: 19,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'CEO SPECIAL ANNOUNCEMENT 6TH JULY 2022',
  viewUrl: 'https://youtu.be/vtjgOZvQUkk'
}, {
  id: 18,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'WHITE PAPER FULL PRESENTATION (2022)',
  viewUrl: 'https://youtu.be/ALwev4EA7aY'
}, {
  id: 17,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'ONE ECOSYSTEM REVEALED HIGHLIGHTS (2022)',
  viewUrl: 'https://youtu.be/jXfNlqlbNYI'
}, {
  id: 16,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'BLOCKCHAIN TECHNOLOGY PRESENTATION (2022)',
  viewUrl: 'https://youtu.be/YoLU0HeHBT0'
}];
export var webinarsData = [{
  id: 1,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'State Of The Nation - OneCoin Webinar - July, 02 2015',
  viewUrl: 'https://www.youtube.com/watch?v=WfQjOdqpx94'
}, {
  id: 2,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'CoinVegas "PLAY AND WIN"',
  viewUrl: 'https://www.youtube.com/watch?v=mxn94xKHTew'
}, {
  id: 3,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'State Of The Nation - OneCoin Webinar - 30.07.2015',
  viewUrl: 'https://www.youtube.com/watch?v=uuzwmjJ9ctQ'
}, {
  id: 4,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'State Of The Nation - OneCoin Webinar - 06 August 2015',
  viewUrl: 'https://www.youtube.com/watch?v=ipEUz-Opy7s'
}, {
  id: 5,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'State Of The Nation - OneCoin Webinar - 14 August 2015',
  viewUrl: 'https://www.youtube.com/watch?v=w_Yn-n9cyfk'
}, {
  id: 6,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin - State Of Nation - Webinar - September 10th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=0-pIB0EgTtw'
}, {
  id: 7,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin - State Of Nation - Webinar - September 17th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=MTtVi4iDfHA'
}, {
  id: 8,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin - State Of Nation - Webinar - September 24th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=_x_7pvmjel4'
}, {
  id: 9,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin - State Of Nation - Webinar - October 1st, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=oOI1oP3XS8s'
}, {
  id: 10,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin - State Of Nation - Webinar - October 8th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=sG47lVwCA5Y'
}, {
  id: 11,
  image: ___EmotionJSX(YoutubeLogo, null),
  title: 'OneCoin - State Of Nation - Webinar - October 15th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=NLTqAgGIMk0'
}];